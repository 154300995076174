<template>
    <div id="container">
        <div id="contents">
            <div class="contents_header">
                <div class="MenuBox">
                    <div class="m1dep">공통코드 관리</div>
                    <div class="m2dep">
                        <ul>
                            <li class="on">공통 분류코드 등록/수정</li>
                            <li class="over" @click="$router.push({name:'ADMCODE02M01'})">공통 코드 등록/수정</li>
                            <li class="over" @click="$router.push({name:'ADMCODE03M01'})">공통 상세코드 등록/수정</li>
                        </ul>
                    </div>
                </div>
            </div>
			<div class="contents_body">
                <div class="Board">
                    <table class="Board_type3">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>	
                            <tr>
                                <th>업무분류코드</th>
                                <td><input type="text" v-model="data.clCode" maxlength="3"></td>
                            </tr>
                            <tr>
                                <th>업무분류코드명</th>
                                <td><input type="text" v-model="data.clCodeNm" maxlength="60"></td>
                            </tr>
                            <tr>
                                <th>업무분류코드설명</th>
                                <td><input type="text" v-model="data.clCodeDc" maxlength="200"></td>
                            </tr>
                            <tr>
                                <th>사용여부</th>
                                <td>
                                    <div class="select_wrap">
                                        <select v-model="data.useAt"> 
                                            <option value="Y" label="예"/>
                                            <option value="N" label="아니요"/>										
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <!--버튼영역-->
                <div class="BtnArea">
                    <div class="Btninner">								
                        <span class="btn write" @click="btnMod" v-if="isModify" >수정</span>
                        <span class="btn list" @click="btnListMod" v-if="isModify">목록</span>
                        <span class="btn write" @click="btnReg" v-if="!isModify">등록</span>
                        <span class="btn list" @click="btnListReg" v-if="isModify">목록</span>
                    </div>
                </div>
                <!--//버튼영역-->
			</div><!--//contents_body-->
        </div><!--//contents-->
    </div><!--//container-->
</template>

<script>

export default {
    data() {
        return {
            data : {
                useAt : 'Y'
            },

            isModify : false,
        }
    },

    beforeCreate() {
      // console.log('beforeCreate');
    },
    created() {
      // console.log('created');
    },
    beforeMount() {
      // console.log('beforeMount');
    },
    mounted() {
      // console.log('mounted');

        if(this.$route.params.clCode != null && this.$route.params.clCode != '') {
            this.isModify = true;
        }

      this.getDetail();
    },
    beforeUpdate() {
      // console.log('beforeUpdate');
    },
    updated() {
    //   console.log('update');
    },
    beforeDestroy() {
      // console.log('beforeDestroy');
    },
    destroyed() {
      // console.log('destroyed');
    },    

    methods : {
        
        getDetail() {

            // 수정일때만 상세 조회
            if(this.$route.params.clCode != null && this.$route.params.clCode != '') {
                
                var param = {
                    clCode: this.$route.params.clCode
                };
                
                this.$.httpPost('/api/code/adm/getClCodeDetail', param)
                    .then(res => {
                        //console.log(res.data);
						this.data = res.data;
                    })
                    .catch(err => {
                        // console.log('=============================');
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('=============================');
                        alert(err.response.data.error_description);
                    });
            }
        },

        btnReg() {

            // console.log(this.data);

            this.$.httpPost('/api/code/adm/regClCode', this.data)
                .then(() => {
                    // console.log(res.data);
                    this.$router.push({name:'ADMCODE01M01'});
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 

                    alert(err.response.data.error_description);
                });
        },

        btnMod() {
            // console.log(this.data);

            this.$.httpPost('/api/code/adm/modClCode', this.data)
                .then(() => {
                    // console.log(res.data);
                    this.$router.push({name:'ADMCODE01M01'});
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 

                    alert(err.response.data.error_description);
                });
        },

        // 수정일때 목록 버튼 클릭
        btnListMod() {
            this.$router.go(-2);
        },

        // 등록일때 목록 버튼 클릭
        btnListReg() {
            this.$router.go(-1);
        }
       
    }

}
</script>
